import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './Layout';
import Location from './Location';

interface LocationData {
  title: string;
  playerIds: string[];
}

class App extends React.Component {
  private readonly locationPlayerIds: { [location: string]: LocationData } = {
    1604: {
      title: '1604',
      playerIds: [
        'f613f8c0-90b2-4442-8a0f-4a226b1456c8',
        'ddbc6aa7-c156-41b6-9db7-d6da7cb7173c',
        'a10515a2-e12a-4646-af7b-87c7366a718a',
      ],
    },
    'thousand-oaks': {
      title: 'Thousand Oaks',
      playerIds: [
        '81d14ae5-acac-4b8f-9896-64aee4ca6de8',
        'CID_YBHQ0000002A',
        'eac65ac1-1824-4e82-8205-16338bd9fc71',
      ],
    },
  };

  private getDefaultLocation(): string {
    return Object.keys(this.locationPlayerIds)[0] || '';
  }

  public render() {
    const defaultLocation = this.getDefaultLocation();

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout locations={this.locationPlayerIds} />}>
            <Route index element={<Navigate to={`/${defaultLocation}`} replace />} />
            {Object.entries(this.locationPlayerIds).map(([key, { playerIds }]) => (
              <Route key={key} path={key} element={<Location key={key} playerIds={playerIds} />} />
            ))}
            {/* Add this catch-all route at the end */}
            <Route path="*" element={<Navigate to={`/${defaultLocation}`} replace />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
