import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';

interface LocationData {
  title: string;
  playerIds: string[];
}

interface Props {
  locations: { [key: string]: LocationData };
}

const Layout: React.FC<Props> = ({ locations }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const activeKey = location.pathname.slice(1) || Object.keys(locations)[0];

  const handleSelect = (key: string | null) => {
    if (key) {
      navigate(`/${key}`);
    }
  };

  return (
    <div className="container-fluid">
      <Tabs id="location-tabs" className="mb-3" activeKey={activeKey} onSelect={handleSelect}>
        {Object.entries(locations).map(([key, { title }]) => (
          <Tab key={key} eventKey={key} title={title} />
        ))}
      </Tabs>
      <Outlet />
    </div>
  );
};

export default Layout;
