import React, { useState, useEffect, useMemo } from 'react';
import PlayerInfo from './models/player-info';
import HdRelayService from './services/hd-relay.service';
import VideoPlayer from './VideoPlayer';

interface Props {
  playerIds: string[];
}

const Location: React.FC<Props> = ({ playerIds }) => {
  const [players, setPlayers] = useState<PlayerInfo[]>([]);

  const hdRelayService = useMemo(() => new HdRelayService(), []);

  useEffect(() => {
    const fetchPlayers = async () => {
      const fetchedPlayers = await Promise.all(
        playerIds.map((playerId) => hdRelayService.getPlayer(playerId)),
      );
      setPlayers(fetchedPlayers);
    };

    fetchPlayers();
  }, [playerIds, hdRelayService]);

  const getVideoPlayerList = (players: PlayerInfo[]) => {
    return players.map((player) => (
      <div key={player.camera.id} className="col-12 col-lg-6">
        <VideoPlayer
          sources={[
            {
              src: `https:${player.server.hls}/camera/${player.camera.id}/relay/playlist.m3u8`,
              type: 'application/x-mpegURL',
            },
          ]}
        />
      </div>
    ));
  };

  return (
    <div className="container-fluid">
      <div className="row g-0 mb-3">{getVideoPlayerList(players)}</div>
    </div>
  );
};

export default Location;
